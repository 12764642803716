
/* INTRO BOXES */

.intro_blue_cards{
    background:#004079;
    color:white;
    padding:0px 20px 0px 30px;
    display:grid;
}

.intro_boxes_titles{
    display:flex;
    align-items:flex-start;
}

.intro_boxes_subtitles{
    font-style:italic;
    display:flex;
    align-items:flex-start;
}

.intro_boxes_key_number{
    font-size:calc(40px + 0.35vw);
    font-style:bold;
    display:flex;
    align-items:flex-end;
}

.intro_boxes_small_number{
    font-size:15px;
}

/* TRADE BALANCE  */

.trade_balance_balance_card{
    background:#004079;
    color:white;
    padding:0px 20px 0px 30px;
}

.trade_balance_title{
    display:flex;
    align-items:flex-start;
}

.trade_balance_key_nb{
    font-size:calc(40px + 0.35vw);
    font-style:bold;
    align-items:flex-end;
    text-align:right;
}

.trade_balance_evol_nb{
    font-size:calc(28px + 0.35vw);
}

.trade_balance_questions{
    font-size:22px;
    padding:0px 20px 0px 30px;
    display:grid;
    text-align:justify;
    align-items:flex-start;
}

.trade_balance_exports_cards{
    background:#CBD8E3;
    color:white;
    padding:0px 20px 0px 30px;
    display:grid;
}

/* EOB CARDS */

.eod_figures_columns{
    background:#f2f2f2;
    display:flex;
    justify-content:center;
    justify-items:center;
    align-content:center;
    align-items:center;
}


.eod_questions_column{
    display:flex;
    justify-content:center;
    justify-items:center;
    align-content:center;
    align-items:center;
}


.eod_figures_boxes_blue{
    background:#004079;
    width:80%;
    box-sizing:border-box;
    display:flex;
    justify-content:center;
    justify-items:center;
    align-content:center;
    align-items:center;
}

.eod_figures_boxes_transparent{
    width:80%;
    color:#004079;
    box-sizing:border-box;
    display:flex;
    justify-content:center;
    justify-items:center;
    align-content:center;
    align-items:center;
}


.eod_questions_boxes{
    justify-content:flex-end;
    justify-items:flex-end;
    display:flex;
    box-sizing:border-box;
    justify-content:center;
    justify-items:center;
    align-content:center;
    align-items:center;
}


.eod_figures_key_nb{
    color:white;
    font-size:calc(30px + 0.35vw);
}


.eod_key_nb_blue_background{
    background:#004079;
    color:white;
    font-size:calc(30px + 0.35vw);
}

.eod_questions_text_bold{
    font-size:calc(10px + 0.5vw);
    font-weight: bold;
}

.eod_questions_text_normal{
    font-size:calc(20px + 0.35vw);
}