/*//////////////////////////////////
FILE STRUCTURE AND MAIN TEXT STYLING 
//////////////////////////////////*/

body {
    margin:0;
    color:#004079;
    padding-top:0px;
    position:relative;
    background:#f2f2f2;
    font-family: 'Noto Sans', sans-serif;
    font-size:22px;
    overflow-x: hidden;
    box-sizing: border-box;
}

/* Dashboard title */

h1 {
    font-size: calc(14px + 0.35vw);
}

/* G&T + P&I Parts titles, Cards titles */

h2 {
    font-size: calc(20px + 0.35vw);
}

/* G&T + P&I Cards subtitles or important text */

h3 {
    font-size: calc(16px + 0.35vw);
    font-weight:normal;
    text-align:justify;
}


ul {
    font-size: calc(16px + 0.35vw);
}

/* Normal text */

p {
    font-size: calc(12px + 0.35vw);
}

.gutters {
    background:#fff;
    padding:30px 30px 0;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    box-sizing: border-box;
}

.margins {
    padding:0px 20px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    box-sizing: border-box;
}


/*//////////////////////////////////
AD HOC TEXT PROPERTIES
//////////////////////////////////*/

.titles_with_red_line_in_boxes{
    color:white;
    font-size: calc(16px + 0.35vw);
    padding-left: 15px;
    border-left: 4px solid #88143c;
}

.titles_with_blue_line_in_box{
    color:white;
    font-size:calc(16px + 0.35vw);
    padding-left: 15px;
    border-left: 4px solid #004079;
}

.titles_with_red_line{
    color:#004079;
    font-weight:bold;
    padding-left: 15px;
    border-left: 4px solid #88143c;
}

.titles_with_blue_line{
    color:#004079;
    padding-left: 15px;
    font-weight:bold;
    border-left: 4px solid #004079;
}

.ekimetrics_tiempos{
    font-family:'TiemposHeadline';
    font-weight:bold;
    font-size:25px;
    text-transform:None;
    color:white;
}

.paragraphs_in_boxes{
    color:white;
    text-align:justify;
}

.left{
	background:#f2f2f2;
	position: fixed;
    width: 15px;
    left: 0;
    right:0;
	}

.last_updated_date{
    text-align:right;
    font-size:calc(12px + 0.35vw);
    font-style: italic;

}

/*//////////////////////////////////
NAVBAR
//////////////////////////////////*/

.navbar{
    display: flex;
    flex-direction:row;
    padding: 1px 0px 1px;
    top: 0;
    text-align:center;
    min-height:50px;
    height:70px;
    background: #004079;
    justify:center;
    align-items:center;
    color: white;
}

.navbar_title{
    color: white;
    font-family: "Noto Sans";
    justify:center;
    align-items:center;
    text-align:center;
}


/*//////////////////////////////////
FOOTER
//////////////////////////////////*/

.footer{
    width: 100%;
    display: flex;
    flex-direction:row;
    left: 0;
    bottom: 0;
    position: -webkit-sticky;
    color:white;
    padding: 20px 40px 20px 40px;
}

.footer_container{
    margin: "30x 80px 50px 60px";
}

.link_to_ccfgb {
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    color:rgba(255,255,255,.7);
    text-decoration:none;
    font-weight:bold;
    cursor: pointer;
    }

/*//////////////////////////////////
INTRO BOXES
//////////////////////////////////*/

.intro_boxes_left{
    background:#004079;
    margin:30px;
    padding:30px 40px 50px 40px;
}

.intro_boxes_right{
    background:#009FE3;
    margin:30px;
    padding:30px 40px 50px 40px;
}

.intro_boxes_bottom{
    background:#e50043;
    margin:30px;
    padding:30px 40px 50px 40px;
}


/*//////////////////////////////////
GRAPHS
//////////////////////////////////*/

.graph_containers{
    background:#CBD8E3;
    color:white;
    padding:5px 20px 5px 20px;
    font-size:calc(12px + 0.35vw);
}

.graph_containers_fdi{
    background:#CBD8E3;
    align-content:center;
    align-items:center;
    justify-content:center;
    justify-items:center;
    color:white;
    padding:5px 5px 5px 5px;
    font-size:calc(12px + 0.35vw);
}
.graph_containers_smaller{
    display:"flex";
    align-content:center;
    align-items:center;
    justify-content:center;
    justify-items:center;
    justify:right;
    direction:column;
    background:white;
    color:black;
    width:100%;
    height:500px;
    /* padding:5px calc(20px - 0.5vw) 5px calc(20px - 0.5vw); */
}

.graph_containers_tiny{
    display:"flex";
    align-content:center;
    align-items:center;
    justify-content:center;
    justify-items:center;
    justify:right;
    direction:column;
    background:white;
    color:black;
    width:100%;
    height:100px;
    /* padding:5px calc(20px - 0.5vw) 5px calc(20px - 0.5vw); */
}

.graph_source{
    display:flex;
    justify-content:flex-end;
    text-align:right;
}


/*//////////////////////////////////
RESIZING
//////////////////////////////////*/

@media screen and (max-width: 750px) {

    .logo_ccfgb {
        display:none;
    }

    .eki_button {
        display:none;
    }

    h2 {
        font-size:calc(15px + 0.35vw);
    }

    
    h3 {
        font-size:calc(12px + 0.35vw);
    }
    
    ul {
        font-size:calc(12px + 0.35vw);
    }


    .gutters{
        padding: 20px 20px 0;
    }

    .graph_containers{
        padding:5px 5px 5px 5px;
    }

    .graph_containers_smaller{
        padding:5px 10px 5px 0px;
    }

}

@media screen and (min-width: 750px) {
    .gutters{
        width:1170px;
        padding: 30px 30px 0;
    }
    
    .graph_containers_smaller{
        padding:10px 5px 20px 5px;
    }

}